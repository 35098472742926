@import 'styles/colors';
@import 'styles/mixins';

.wrapper {
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.welcome {
  ul {
    margin-bottom: 0;
  }

  margin: 0 -15px;
  p,
  h2 {
    padding: 0 6px;
  }

  & > h2 {
    position: fixed;
    left: -9999px;
  }

  @include desktop {
    p,
    h2 {
      padding: initial;
    }
  }
  .action {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @include desktop {
      padding-bottom: 275px;
    }

    @include desktopFull {
      background-size: 1441px 650px;
      background-position: bottom;
    }

    .heading {
      max-width: 320px;
      margin-top: 110px;

      @include desktop {
        margin-top: 160px;
        max-width: 770px;
        position: relative;
      }

      h1,
      p {
        text-align: center;
      }
      h1 {
        @include desktop {
          font-size: 2.8rem;
        }
      }
    }

    .subscriptionInfo {
      font-size: 16px;
      max-width: 345px;
      margin-top: 24px;
      text-align: center;

      @include desktop {
        max-width: 461px;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 12px;

      @include desktop {
        flex-direction: row;
      }
    }

    a {
      min-width: 345px;
      // width: 100%;

      @include desktop {
        min-width: 193px;
        &:first-child {
          min-width: 304px;
        }
      }
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .fromTopGradient {
    background: linear-gradient(180.03deg, #0d1321 0.02%, rgba(13, 19, 33, 0) 99.97%);
  }

  .secMargin {
    margin-top: 60px;
  }

  .section {
    max-width: 1170px;
    margin: 0 auto;

    @include mobileLarge {
      padding-bottom: 40px;
    }

    @include desktop {
      // flex-direction: row;
      padding-top: 0;
      padding-bottom: 0;
    }

    .info {
      display: flex;
      max-width: 460px;
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;
      padding-bottom: 80px;
      z-index: 1;
      margin: 0 auto;

      @include desktop {
        margin: 0;
      }

      h2 {
        text-align: center;

        @include desktop {
          text-align: initial;
        }
      }

      p {
        margin-top: 24px;
        text-align: center;
        @include desktop {
          text-align: initial;
        }
      }

      a {
        margin-top: 48px;
        display: block;
        width: 345px;

        @include desktop {
          width: auto;
        }
      }

      @include desktop {
        align-items: flex-start;
        padding-top: 180px;
        padding-bottom: 180px;
      }
    }

    .pic {
      max-width: 1180px;
      position: relative;

      @include desktop {
        height: 770px;
      }

      img {
        height: 380px;
        width: 100vw;
        object-fit: cover;
        max-width: 1090px;

        @include desktop {
          height: 100%;
          object-fit: initial;
        }
      }

      div {
        text-align: center;
        padding: 0 15px;
        padding-top: 40px;

        @include desktop {
          text-align: initial;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          padding: 0;
        }
      }
    }

    .pic1 {
      img {
        @include desktop {
          float: right;
          margin-right: -260px;
        }
      }

      div {
        @include desktop {
          padding-right: 710px;
          padding-top: 160px;
        }
      }
    }

    .pic2 {
      img {
        @include desktop {
          margin-left: -210px;
        }
      }

      div {
        @include desktop {
          padding-left: 700px;
          padding-top: 260px;
        }
      }
    }
  }

  .reverse {
    position: relative;

    // margin-top: 180px;

    // @include mobileLarge {
    //   margin-top: 280px;
    // }
    @include desktop {
      // justify-content: center;
      flex-direction: row-reverse;
      display: flex;
      margin-top: 0;
    }

    .info {
      padding: 0;
      margin-top: 220px;

      @include desktop {
        margin-top: 0;
        padding-left: 30px;
        height: 761px;
        justify-content: center;
      }
    }
  }

  .theaters {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    max-width: 1170px;
    margin-top: 50px;
    padding: 0 10px;

    ul {
      list-style: none;
      max-width: 673px;
      display: flex;
      margin: 0;
      margin-right: 0;
      justify-content: initial;

      li {
        width: 70px;
        height: 70px;
        border-radius: 70px;
        color: transparent;
        position: relative;
        background-color: #010118;
        z-index: 0;
        margin-left: -3px;
        cursor: pointer;
        transition: transform 0.2s ease;

        a {
          text-indent: -9999px;
        }

        &.more {
          a {
            text-indent: initial;
          }
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 70px;
          border: 4px solid #010118;
          box-sizing: content-box;
        }

        a {
          width: 100%;
          z-index: 2;
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
        }

        &:hover {
          transform: translateY(-5px);
          .tooltiptext {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .tooltiptext {
        display: block;
        text-indent: 0;
        opacity: 0;
        position: absolute;
        padding: 4px 12px;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.7);
        background-color: rgba(72, 72, 72, 0.48);
        border-radius: 60px;
        top: -42px;
        left: -60px;
        width: 180px;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        visibility: hidden;
        transition: opacity 0.2s ease;
      }

      .more {
        font-size: 24px;
        line-height: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-indent: 0;
        color: #ffffff;
        background-color: #121223;
        border: 4px solid $color-body-bg;
        box-sizing: content-box;
      }
    }

    p {
      text-align: center;

      @include desktop {
        max-width: 300px;
      }

      position: relative;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      margin-left: 0;
      margin-top: 32px;

      &::before {
        content: '';
        display: none;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.12);
        height: 70px;
        width: 1px;
        left: -64px;
      }
    }

    &:before {
      content: '';
      display: block;
      height: 100%;
      top: -50px;
      position: absolute;
      z-index: -1;
      right: -100vw;
      left: -100vw;
      border-top: 1px solid $color-border;
    }

    @include carouselMaxWidth {
      ul {
        justify-content: center;
      }
    }

    @include desktop {
      flex-direction: row;
      padding: 0;

      ul {
        margin-right: 64px;
        justify-content: flex-start;
        padding: 0;
      }

      p {
        margin-top: 0px;
        text-align: left;
        margin-left: 64px;
        &::before {
          display: inline-block;
        }
      }
    }
  }

  .imgTiles {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    margin-top: 41px;
    align-items: center;
    position: relative;
    max-width: 1170px;
    padding-top: 60px;

    .imgCol {
      list-style: none;
      padding-right: 20px;
      padding-left: 20px;
      width: 100%;

      li {
        flex: 1;
      }

      @include desktop {
        display: flex;
        flex-direction: column;
        max-width: 570px;
        // width: 570px;
        padding: 0;

        &:first-child {
          margin-right: 30px;
        }
      }

      a {
        display: block;
      }
    }

    .imgTile {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      border-radius: 8px;
      height: 270px;
      transition: transform 0.25s;
      position: relative;

      ul {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        overflow: hidden;
      }

      h3 {
        font-size: inherit;
        text-align: center;
      }

      span {
        img {
          max-width: 90%;
          display: block;
          margin: 0 auto;
          margin-bottom: 10px;

          @include mobileLarge {
            height: 100px;
          }
        }
      }

      &:first-child {
        margin-bottom: 30px;
      }

      &Large {
        @include desktop {
          height: 570px;
        }
      }

      &:hover {
        transform: scale(0.99);
        backface-visibility: hidden;
      }

      em {
        content: '';
        display: block;
        height: 270px;
        transition: transform 0.25s;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        text-align: center;
        // overflow: hidden;

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          border-radius: 8px;
        }
      }

      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
      }

      // span {
      //   position: absolute;
      //   display: block;
      //   width: 100%;
      //   background-repeat: no-repeat;
      //   z-index: 1;
      //   background-position: center;
      //   background-size: contain;
      //   bottom: 20px;
      //   border: 1px solid red;
      // }
    }

    .library {
      margin-top: 20px;
      min-height: 270px;
      height: auto;
      text-indent: initial;

      em {
        display: none;
      }
      background: rgba(72, 72, 72, 0.24);

      ul {
        width: 100%;
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;

        li {
          flex-basis: 33.33%;
          height: 128px;
          opacity: 0.5;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }

          position: relative;
        }
      }

      div {
        max-width: 370px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        text-align: center;
        margin-top: 24px;
        z-index: 2;

        strong {
          font-size: 28px;
          line-height: 24px;
          padding: 0 15px;
          display: inline-block;
        }

        p {
          padding: 0 15px 32px 15px;
        }
      }

      .lib1 {
        border-top-left-radius: 8px;
      }

      .lib3 {
        border-top-right-radius: 8px;
      }
    }

    @include desktop {
      flex-direction: row;
      height: 100%;
      align-items: stretch;
      background-position: center;

      .imgTile {
        width: 100%;

        &::before {
          bottom: 40px;
        }
      }

      .library {
        margin-top: 0;
        div {
          h2 {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
    }

    @include mobileLarge {
      .img-tile {
        height: 270px;
      }

      .silenstvi {
        height: 570px;
        // &::after {
        //   // height: 570px;
        // }

        &::before {
          height: 168px;
        }
      }
    }
  }

  .support {
    position: relative;
    background: linear-gradient(0deg, #0d1321 0.02%, rgba(13, 19, 33, 0) 99.97%);

    &:before {
      content: '';
      display: block;
      height: 100%;
      top: 0;
      position: absolute;
      z-index: -1;
      right: -100vw;
      left: -100vw;
      border-top: 1px solid $color-border;
    }

    .section {
      flex-direction: column;
      padding-top: 80px;
      padding-bottom: 80px;
      @include desktop {
        flex-direction: row;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .faq {
    margin: 0 auto;
    max-width: 770px;
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 80px 10px 0 10px;

    @include desktop {
      margin-top: 120px;
      margin-bottom: 120px;
      padding: 0;
      padding-top: 100px;
    }

    h2 {
      text-align: center;
      margin-bottom: 56px;

      @include desktop {
        margin-bottom: 72px;
      }
    }
  }

  .try {
    position: relative;
    background: linear-gradient(0deg, #0d1321 0.02%, rgba(13, 19, 33, 0) 99.97%);

    &:before {
      content: '';
      display: block;
      height: 100%;
      top: 0;
      position: absolute;
      z-index: -1;
      right: -100vw;
      left: -100vw;
      border-top: 1px solid $color-border;
    }

    .tryDramox {
      padding: 80px 0;
      text-align: center;

      display: flex;
      flex-direction: column;
      align-items: center;

      margin-top: 0;
      margin-bottom: 0;

      h2 {
        margin-bottom: 16px;
      }

      p {
        margin-bottom: 40px;
      }

      a {
        width: 354px;

        @include desktop {
          width: auto;
        }
      }

      @include desktop {
        padding: 120px 0;
      }
    }
  }
}

.landing {
  overflow: hidden;
}

.thumb {
  height: 230px;
  width: 100vw;
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  @include desktop {
    height: 710px;
    width: 100vw;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }

  .overlay {
    display: none;
    background: rgba($color-gradient-base, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    @include desktop {
      display: block;
    }
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100vw;
    left: 0;
    right: 0;
    z-index: 2;
  }

  &:before {
    height: 56px;
    top: 0;
    background: linear-gradient(360deg, rgba($color-gradient-base, 0) 0%, $color-body-bg 100%);

    @include desktop {
      height: 244px;
    }
  }

  &:after {
    height: 112px;
    bottom: 0;
    background: linear-gradient(180deg, rgba($color-gradient-base, 0) 0%, $color-body-bg 100%);

    @include desktop {
      height: 136px;
    }
  }
}
