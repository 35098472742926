@import 'styles/colors';
@import 'styles/mixins';

.h1 {
  height: 106px;
  width: 30vw !important;
}

.p {
  width: 20vw !important;
  height: 53px;
}

.wrapper {
  transition: 0.25s ease-in-out;
  transition-property: opacity;

  &.visible {
    opacity: 1;
  }
}

.welcome {
  margin: 0 -15px;
  p,
  h2 {
    padding: 0 6px;
  }

  & > h2 {
    position: fixed;
    left: -9999px;
  }

  @include desktop {
    p,
    h2 {
      padding: initial;
    }
  }
  .action {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    @include desktopFull {
      background-size: 1441px 650px;
      background-position: bottom;
    }

    .heading {
      max-width: 320px;
      margin-top: 110px;
      @include desktop {
        margin-top: 160px;
        max-width: 770px;
      }

      h1,
      p {
        text-align: center;
      }
      h1 {
        @include desktop {
          font-size: 2.8rem;
        }
      }
    }

    .subscriptionInfo {
      font-size: 16px;
      max-width: 345px;
      margin-top: 24px;
      text-align: center;

      @include desktop {
        max-width: 461px;
      }
    }

    a,
    em {
      width: 345px;
      // width: 100%;

      @include desktop {
        width: 304px;
      }
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .secMargin {
    margin-top: 60px;
  }

  .section {
    // display: flex;
    // flex-direction: column;
    max-width: 1170px;
    // margin: 0 auto;
    // align-items: center;
    // justify-content: space-between;
    padding-top: 60px;
    margin: 0 auto;
    padding-bottom: 120px;

    @include mobileLarge {
      padding-bottom: 152px;
    }

    @include desktop {
      // flex-direction: row;
      padding-top: 0;
      padding-bottom: 0;
    }

    .info {
      display: flex;
      max-width: 460px;
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;
      padding-bottom: 80px;
      z-index: 1;
      margin: 0 auto;

      @include desktop {
        margin: 0;
      }

      h2 {
        text-align: center;

        @include desktop {
          text-align: initial;
        }
      }

      p {
        margin-top: 24px;
        text-align: center;
        @include desktop {
          text-align: initial;
        }
      }

      a,
      em {
        margin-top: 48px;
        display: block;
        width: 345px;

        @include desktop {
          width: auto;
        }
      }

      @include desktop {
        align-items: flex-start;
        padding-top: 180px;
        padding-bottom: 180px;
      }
    }
  }

  .reverse {
    position: relative;

    // margin-top: 180px;

    // @include mobileLarge {
    //   margin-top: 280px;
    // }
    @include desktop {
      // justify-content: center;
      flex-direction: row-reverse;
      display: flex;
      margin-top: 0;
    }

    .info {
      padding: 0;
      margin-top: 220px;

      @include desktop {
        margin-top: 0;
        padding-left: 30px;
        height: 761px;
        justify-content: center;
      }
    }
  }

  .theaters {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    max-width: 1170px;
    margin-top: 50px;
    padding: 0 10px;

    ul {
      list-style: none;
      max-width: 673px;
      display: flex;
      margin: 0;
      margin-right: 0;
      justify-content: initial;

      li {
        width: 70px;
        height: 70px;
        border-radius: 70px;
        text-indent: -9999px;
        color: transparent;
        position: relative;
        background-color: #010118;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -12px;
        cursor: pointer;
        transition: transform 0.2s ease;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        a,
        em {
          width: 100%;
          z-index: 2;
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          transform: translateY(-5px);
          .tooltiptext {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .tooltiptext {
        display: block;
        text-indent: 0;
        opacity: 0;
        position: absolute;
        padding: 4px 12px;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.7);
        background-color: rgba(72, 72, 72, 0.48);
        border-radius: 60px;
        top: -35px;
        left: -41px;
        width: 180px;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        visibility: hidden;
        transition: opacity 0.2s ease;
      }
    }

    p {
      max-width: 300px;
      text-align: center;

      position: relative;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      margin-left: 0;
      margin-top: 32px;
    }

    @include carouselMaxWidth {
      ul {
        justify-content: center;
      }
    }

    @include desktop {
      flex-direction: row;
      padding: 0;

      ul {
        margin-right: 64px;
        justify-content: flex-start;
        padding: 0;
      }

      p {
        margin-top: 0px;
        text-align: left;
        margin-left: 64px;
        &::before {
          display: inline-block;
        }
      }
    }
  }

  .imgTiles {
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    margin-top: 90px;
    align-items: center;
    position: relative;
    max-width: 1170px;
    padding-top: 60px;

    .imgCol {
      list-style: none;
      padding-right: 20px;
      padding-left: 20px;
      width: 100%;

      li {
        flex: 1;

        & + li {
          margin-top: 30px;
        }
      }

      @include desktop {
        display: flex;
        flex-direction: column;
        max-width: 570px;
        // width: 570px;
        padding: 0;

        &:first-child {
          margin-right: 30px;
        }
      }

      a,
      em {
        display: block;
      }
    }

    .imgTile {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      border-radius: 8px;
      height: 270px;
      transition: transform 0.25s;
      position: relative;

      &:hover {
        transform: scale(0.99);
        backface-visibility: hidden;
      }

      &::after {
        content: '';
        display: block;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        height: 270px;
        transition: transform 0.25s;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }

      span {
        position: absolute;
        display: block;
        width: 100%;
        background-repeat: no-repeat;
        z-index: 1;
        background-position: center;
        background-size: contain;
        bottom: 20px;
      }
    }
  }
}
